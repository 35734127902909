import axios from "axios";

const { REACT_APP_ILLUMINA_URL } = process.env;

export const login = async () => {
  try {
    const { data, status } = await axios.post(`${REACT_APP_ILLUMINA_URL}/api/v0/login`, {username: 'andrea.colarossi@laserromae.it', password: 'qwe'});
    return { result: data.result, status };
  } catch (error) {
    console.error(error);
    return { result: null, status: error.response?.status ?? 500 };
  }
}

export const startSession = async (session) => {
  try {
    const { data, status } = await axios.post(`${REACT_APP_ILLUMINA_URL}/api/v0/session/instance`, session, {withCredentials: true});
    console.log(data);
    return { result: data.result, status };
  } catch (error) {
    console.error(error);
    return { result: null, status: error.response?.status ?? 500 };
  }
}

/* payload structure:
* {
*   datasetName: string,
*   spatialFilter: {
*     filterType: string, [mbr]
*     loweLeft: {
*       latitude: number, longitude: number
*     },
*     upperRight: {
*       latitude: number, longitude: number
*     },
*   },
*   temporalFilter: {
*     start: string [2000-12-10],
*     end: string
*   }
* }
* */
export const getCatalog = async ({dataset, ne, sw, start, end}, apiKey) => {
  try {
    const { data, status } = await axios.post(`/api/v0/dataset`,
        {
          datasetName: dataset,
          spatialFilter: {lowerLeft: sw, upperRight: ne},
          temporalFilter: { start, end }
        },
        { headers: { 'X-Auth-Token': apiKey } });


    // browse.thumbnailPath,
    // displayId,
    // "temporalCoverage":{
    //  "endDate":"2022-07-10 00:00:00",
    //  "startDate":"2022-07-10 00:00:00"
    //  },
    //  "publishDate":"2022-07-21 19:19:43-05"

    console.log(data.result)

    const links = data.result.filter(el => el.downloadLinks?.length > 0).map(el => ({
      displayId: el.displayId,
      thumbnail: el.thumbnail,
      entityId: el.entityId,
      temporalCoverage: {
        endDate: el.temporalCoverage.endDate,
        startDate: el.temporalCoverage.startDate,
      },
      publishDate: el.publishDate,
      downloadLinks: el.downloadLinks[0]
    }))

    return { result: links, status };
  } catch (error) {
    console.error(error);
    return { result: null, status: error.response?.status ?? 500 };
  }
}

export const loginNASA = async () => {
  try {
    const { data, status } = await axios.post(`/api/v0/login`,
        {username: 'fdandria', password: '48K9mmS|czGG7zh', authType: 'EROS', catalogId: 'EE'});
    return { result: data.result, status };
  } catch (error) {
    console.error(error);
    return { result: null, status: error.response?.status ?? 500 };
  }
}



