import React, {useEffect, useRef, useState} from "react";
import L from "leaflet";
import "react-leaflet-draw";

const at = 'pk.eyJ1IjoiYWNvbGFyb3NzaSIsImEiOiJja3pwY2gwZnQzZzRjMnBvMHNpZGdrNDhsIn0.d-3HF3y7l4ly1X5M5TWhdA';

const LeafLetMap = ({onChangeCoordinates}) => {

  const [map, setMap] = useState(null);
  const [zoom, setZoom ] = useState(6);

  const [ coordinates, setCoordinates ] = useState({ne: {}, sw: {}});

  const [ rect, setRect ] = useState(null);

  const addRect = (rect) => {
    setRect(rect);
    if (rect) {
      onChangeCoordinates({ne: rect._bounds._northEast, sw: rect._bounds._southWest});
    } else {
      onChangeCoordinates({ne: {}, sw: {}});
    }
  }

  useEffect(() => {
    const map = L.map('map', {zoomAnimationThreshold: 20, drawControl: false, zoomControl: false}).setView([43.902782, 12.496366], zoom);
    L.tileLayer(`https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token=${at}`, {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
      maxZoom: 18,
      id: 'mapbox/streets-v11',
      tileSize: 512,
      zoomOffset: -1,
    }).addTo(map);

    const drawControl = new L.Control.Draw({
      draw: {polyline: false, polygon: false, marker: false, circle: false, rectangle: true, circlemarker: false},
      options: {color: '#009BDE'}
    })
    L.control.zoom({position: 'topright'}).addTo(map)
    map.addControl(drawControl)

    map.on('draw:created', function (e) {
      Object.entries(map._layers).filter(e => e[1] instanceof L.Rectangle).map(rect => map.removeLayer(rect[1]));
      const bounds = [[e.layer._bounds._southWest.lat, e.layer._bounds._southWest.lng], [e.layer._bounds._northEast.lat, e.layer._bounds._northEast.lng]]
      const rect = L.rectangle(bounds, {color: "#009BDE", weight: 1}).addTo(map);
      rect.on('contextmenu', (e) => {
        e.originalEvent.preventDefault();
        map.removeLayer(rect);
        onChangeCoordinates({ne: {}, sw: {}});
      })
      // map.fitBounds(bounds);
      addRect(rect);
    });
    setMap(map);
    document.querySelector('.leaflet-draw-section').style.visibility = 'hidden';
  }, []);

  return (
      <div className="flex-1 h-full z-10" >
        <link rel="stylesheet" href="https://unpkg.com/leaflet@1.7.1/dist/leaflet.css"
              integrity="sha512-xodZBNTC5n17Xt2atTPuE1HxjVMSvLVW9ocqUKLsCC5CXdbqCmblAshOMAS6/keqq/sMZMZ19scR4PsZChSR7A=="
              crossOrigin=""/>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/leaflet.draw/0.4.2/leaflet.draw.css"/>
        <div id="map" className={'h-full w-full'} />
      </div>
  )
}

export default LeafLetMap;
