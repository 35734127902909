import React, {useEffect, useMemo, useRef, useState} from "react";
import LeafLetMap from "./components/LeafLetMap";

import Logo from "./asset/img/laser_logo.svg"
import { getCatalog, login, loginNASA, startSession } from "./service/data";
import moment from 'moment';
import {CheckboxInput, TextInput} from "./components/Input";
import Check from './asset/icons/area.svg'

const App = () => {

  const [ start, setStart ] = useState(null);
  const startRef = useRef();
  const [ end, setEnd ] = useState(null);
  const endRef = useRef();


  // page status
  const [ isGettingDataset, setIsGettingDataset ] = useState(false);
  const [ isSendingExecute, setIsSendingExecute ] = useState(false);

  // get dataset step data
  const [ coordinates, setCoordinates ] = useState({ne: {}, sw: {}});
  const onChangeCoordinates = (value) => setCoordinates(value);

  // execute session step data
  const [selectedDatasets, setSelectedDatasets] = useState({});
  const onChangeSelectedDatasets = ({id, links}) => setSelectedDatasets({
    ...selectedDatasets, [id]: {
      checked: !selectedDatasets[id]?.checked,
      links
    }
  });
  const [links, setLinks] = useState([]);

  useEffect(() => {
    login();
  }, []);

  const isValidFilter = useMemo(() => {
    const startTime = moment(start);
    const endTime = moment(end);
    console.log(start, end)
    return startTime.isBefore(endTime) && !!coordinates.ne.lat && !!coordinates.sw.lat
  }, [start, end, coordinates]);

  const onSearchDataset = async () => {
    setSelectedDatasets({});
    setIsGettingDataset(true);
    const loginResponse = await loginNASA();
    if (loginResponse.result) {
      const {result} = await getCatalog({
        dataset: 'L8',
        ne: {latitude: coordinates.ne.lat, longitude: coordinates.ne.lng},
        sw: {latitude: coordinates.sw.lat, longitude: coordinates.sw.lng},
        start: start.split(" ")[0],
        end: end.split(" ")[0],
      }, loginResponse.result);
      if (result) {
        setLinks(result);
      }
    }
    setIsGettingDataset(false);
  }

  const onStartSentinel = async () => {
    const sessionBody = {
      uuid:"646ca430d77c3c0ee496be50",
      name:"NDVI",
      workflow_uuid:"5fad19a27e9e6080bacd6865",
      attempts:0,
      notifications:[],
      group:"5f55f4951a88b5f52b7c5c3f",
      session_variables:{
        "i607c5931-24d8-11eb-87da-ed40e91a9ec0":[
          {name:"BBOX",value:"6.424491,46.335303:19.426811,36.531421"}
        ],
        "i61644831-24d8-11eb-87da-ed40e91a9ec0":[],
        "i62909331-24d8-11eb-87da-ed40e91a9ec0":[
          {name:"HOSTNAME", value:"maps.dev.laserromae.it"},
          {name:"USERNAME",value:"admin"},
          {name:"PASSWORD",value:"8a7KeK6THTTwGhGFsEwnqNcfZtb2edbm"}
        ],
        "ia6ed0dc1-24eb-11eb-92b5-d5191dc58da6":[
          {name:"USERNAME",value:"fdandria"},
          {name:"PASSWORD",value:"fabi0fab1o"},
          {name:"MISSION",value:"Sentinel-2"},
          {name:"LIMIT",value:"3"},
          {name:"INSTRUMENT",value:"MSI"},
          {name:"STARTTIME",value:"2020-10-01T00:00:00.000Z"},
          {name:"ENDTIME",value:"2020-12-31T23:59:59.999Z"},
          {name:"TYPE",value:"OL_1_ERR__"},
          {name:"BBOX",value:"6.424491,46.335303:19.426811,36.531421"}
        ]
      },
      sandbox_resources:{
        "i607c5931-24d8-11eb-87da-ed40e91a9ec0":{cpu:2000,memory:8192,disk:10240},
        "i61644831-24d8-11eb-87da-ed40e91a9ec0":{cpu:4000,memory:8192,disk:10240},
        "i62909331-24d8-11eb-87da-ed40e91a9ec0":{cpu:1000,memory:2048,disk:1024},
        "ia6ed0dc1-24eb-11eb-92b5-d5191dc58da6":{cpu:1000,memory:2048,disk:1024}
      }, cluster_pref:[""], has_cluster_pref:false}
    setIsSendingExecute(true);
    const {result, status} = await startSession(sessionBody);
    setIsSendingExecute(false);
    console.log(result, status);
  }

  const onSubmitSession = async () => {

    const newSessionBody = {
      "uuid":"628799f28b990ab933c078d2",
      "name":"EOSIP",
      "workflow_uuid":"628799dde1f972632c799ab6",
      "attempts":0,
      "notifications":[],
      "group":"5f55f4951a88b5f52b7c5c3f",
      "session_variables":{"i1e267241-d842-11ec-9542-8762b4fdf060":[{"name":"CATALOG"},{"name":"FILE_LIST"}],"i1f690461-d842-11ec-9542-8762b4fdf060":[]},
      "sandbox_resources":{"i1e267241-d842-11ec-9542-8762b4fdf060":{"cpu":1000,"memory":1536,"disk":102},"i1f690461-d842-11ec-9542-8762b4fdf060":{"cpu":4000,"memory":12288,"disk":10240}},
      "cluster_pref":[""],"has_cluster_pref":false}

    const sessionBody = {
      uuid: "62de88561c1e139e983accbb",
      name: "testing_eosip",
      workflow_uuid: "62de86a9031449a16cec6965",
      attempts: 0,
      notifications: [],
      group: "5f55f4951a88b5f52b7c5c3f",
      session_variables: {
        "i1e267241-d842-11ec-9542-8762b4fdf060": [{name: "FILE_LIST", value: Object.values(selectedDatasets).map(v => v.links).join(',')}],
        "i1f690461-d842-11ec-9542-8762b4fdf060": [],
      },
      sandbox_resources: {
        "i1e267241-d842-11ec-9542-8762b4fdf060": {cpu: 1000, memory: 1536, disk: 102},
        "i1f690461-d842-11ec-9542-8762b4fdf060": {cpu: 4000, memory: 16384, disk: 10240},
      },
      cluster_pref: [""],
      has_cluster_pref: false
    };



    setIsSendingExecute(true);
    const {result, status} = await startSession(newSessionBody);
    setIsSendingExecute(false);
    console.log(result, status);
  }

  const formatDate = (time) => {
    console.log(time)
    moment(time)
    if (!time) {
      return 'Select date and time'
    }
    return moment(time).format('DD/MM/YYYY - H:mm A')
  }

  const settingsRef = useRef();
  const baseSettingsRef  = useRef();
  const firstSettingsRef = useRef();

  const onClickSelectArea = () => {
    const button = document.querySelector('.leaflet-draw-draw-rectangle')
    if (button) {
      button.click()
    }
  }

  return <div className={'h-screen'}>

    <div className={'h-[4.5rem] bg-[#191F56]'}>
      <div className={'w-fit py-[1.5rem] p-12'}>
        <img src={Logo} alt={'laserromae-logo'}/>
      </div>
    </div>

    <div className={'flex'} style={{height: `calc(100% - 72px)`}}>
      <div className={'flex w-screen relative'}>

        <div className={`absolute top-14 left-12 bg-white w-[25rem] rounded-3xl z-50 shadow-lg flex flex-col ${links.length > 0 ? '' : 'pb-7'}`} style={{height: `${links.length > 0 ? 'calc(100% - 120px)' : ''}`}} ref={settingsRef}>

          <div className="py-6 pb-7 px-9 shadow-md rounded-3xl" ref={baseSettingsRef}>
            <p className="text-black text-sm font-bold">Select a period</p>
            <div className="py-5 px-3.5 bg-primary rounded-xl mt-2.5 flex flex-row text-xs justify-between text-white items-center">
              <div>
                <div className="font-bold">From</div>
                <div className="relative my-1">
                  <div className={`absolute security-timepicker select-none cursor-pointer ${start ? '' : 'italic'}`} onClick={() => {
                    startRef.current.showPicker()
                  }}>{formatDate(start)}</div>
                  <input className="invisible security-timepicker select-none" placeholder="Select date and time" type="datetime-local"
                         ref={startRef}
                         onChange={(e) => setStart(e.target.value)}
                         onClick={() => {
                           startRef.current.showPicker()
                         }}/>
                </div>
              </div>
              <div className="h-8 border rounded-xl border-white mx-1"/>
              <div className="">
                <div className="font-bold">To</div>
                <div className="relative my-1">
                  <div className={`absolute security-timepicker select-none cursor-pointer ${end ? '' : 'italic'}`} onClick={() => {
                    endRef.current.showPicker()
                  }}>{formatDate(end)}</div>
                  <input className={`invisible security-timepicker select-none ${end ? '' : 'italic'}`} placeholder="Select date and time" type="datetime-local"
                         ref={endRef}
                         onClick={() => {
                           endRef.current.showPicker()
                         }}
                         onChange={(e) => setEnd(e.target.value)}/>
                </div>
              </div>
            </div>
          </div>
          <div ref={firstSettingsRef} className="pt-7 px-9 flex flex-col">

            <p className="font-bold text-sm text-black">Coordinates</p>
            <div className="pt-3.5 pb-2 px-3.5 bg-[#EBEBEB] my-3 rounded-xl">
              <div className="font-semibold text-xs text-primary">Nord-East</div>
              <TextInput className={coordinates.ne ? 'text-primary' : "italic"}
                         placeholder="Write the coordinates or select area"
                         value={coordinates.ne?.lat ? `${coordinates.ne?.lat} : ${coordinates.ne?.lng}` : ''}
                         readOnly={true}
              />
            </div>
            <div className="pt-3.5 pb-2 px-3.5 bg-[#EBEBEB] mt-3 mb-1 rounded-xl">
              <div className="font-semibold text-xs text-primary">South-West</div>
              <TextInput className={coordinates.sw ? 'text-primary' : "italic"}
                         placeholder="Write the coordinates or select area"
                         value={coordinates.sw?.lat ? `${coordinates.sw?.lat} : ${coordinates.sw?.lng}` : ''}
                         readOnly={true}
              />
            </div>
            <div className="flex space-x-2 mt-4 mb-2">
              <button className="btn" onClick={onSearchDataset} disabled={!isValidFilter || isGettingDataset}>Get Datasets</button>
              <button className="btn" onClick={onSubmitSession} disabled={!Object.values(selectedDatasets).some(x => x) || isSendingExecute}>Execute</button>
            </div>

            {links.length > 0 && <p className="font-bold text-sm text-black mt-4">Results</p>}
          </div>

          {links.length > 0 && <div className="overflow-y-scroll grow mx-9 pr-1.5 mb-7"
                style={{height: `calc(100% - ${settingsRef.current?.offsetHeight ?? 0}px - ${baseSettingsRef.current?.offsetHeight ?? 0}px - ${firstSettingsRef.current?.offsetHeight ?? 0}px)`}}>
            <div className="-mb-2.5">
              {links.map(l => <div className={'h-[78px] bg-[#EBEBEB] rounded-xl flex mb-2.5 relative overflow-hidden'} style={{overflowWrap: 'anywhere'}}>
                <CheckboxInput id={`${l.displayId}-check`} checked={selectedDatasets[l.displayId]?.checked} className="absolute left-2"
                               onToggle={() => onChangeSelectedDatasets({id: l.displayId, links: l.downloadLinks})}/>
                <img src={l.thumbnail} width={78} height={78} alt={l.displayId}/>
                <div className="p-2 pl-3.5 text-[9px] leading-1">
                  <div className={'text-[8px] leading-1 font-semibold cursor-pointer mb-1'} onClick={() => onChangeSelectedDatasets({id: l.displayId, links: l.downloadLinks})}>{l.displayId}</div>
                  <div className="">{l.temporalCoverage.endDate}</div>
                  <div className="">{l.temporalCoverage.startDate}</div>
                  <div className="">{l.publishDate}</div>
                </div>
              </div>)}

            </div>
          </div>
          }        </div>

        <div className="z-20">
          <button onClick={onClickSelectArea}
                  className={"flex flex-row space-x-2 items-center py-3 justify-center text-base font-bold bg-[#009BDE] text-white rounded-md bottom-16 absolute left-0 right-0 mr-auto ml-auto w-[232px]"}>
            <img src={Check} alt={'check button'}/>
            <span>Select area</span>
          </button>
        </div>

        <LeafLetMap onChangeCoordinates={onChangeCoordinates}/>
      </div>

    </div>
  </div>
}

export default App;