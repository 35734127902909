import {Listbox, Transition} from '@headlessui/react'
import {Fragment, useEffect, useRef, useState} from 'react'
import {CheckIcon, ChevronUpDownIcon} from "@heroicons/react/20/solid";

const TextInput = ({className, value, onChange, label, placeholder, readOnly}) => {

  return <div className="flex flex-col space-y-2 py-1">
    {label && <span className="text-xs font-semibold">{label}</span>}
    <input type="text" className={`cv-input ${className}`} value={value} onChange={onChange} placeholder={placeholder} readOnly={readOnly}/>
  </div>
}

const SelectInput = ({value, onChange, options = [], label, placeholder = "Seleziona l'ambito di interesse"}) => {

  return (
    <div className="flex flex-col space-y-2 py-1">
      {label && <span className="text-xs font-semibold">{label}</span>}
      <div className="">
        <Listbox value={value} onChange={onChange}>
          <div className="relative mt-1 ">

            <Listbox.Button className="relative w-full cv-input cursor-default text-left text-xs">
              <span className={`block truncate ${!value ? 'text-[#8A8A8A]': ''}`}>{value || placeholder}</span>
              <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400 fill-[#444293]" aria-hidden="true"/>
                            </span>
            </Listbox.Button>

            <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
              <Listbox.Options
                className="absolute mt-[1px] max-h-60 w-full overflow-auto rounded-md bg-white py-2 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-20">
                {options.map((opt, personIdx) => (
                  <Listbox.Option
                    key={personIdx}
                    className={({active, selected}) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${selected || active ? 'bg-[#444293] text-white font-semibold' : 'text-[#8A8A8A]'}`
                    }
                    value={opt}
                  >
                    {({selected}) => (
                      <>
                        <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>{opt}</span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                                                        <CheckIcon className="h-3 w-3 fill-white"  aria-hidden="true"/>
                                                    </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </Listbox>
      </div>
    </div>
  )
}

const RadioInput = ({checked, onToggle, label, id, className}) => {

  return <div className={`flex flex-row items-center ${className}`}>
    <div className="radio">
      <input type='radio' className="radio__input" id={id}
             onChange={onToggle} checked={checked}
             disabled={false}/>
      <div className="radio__box" onChange={onToggle}/>
    </div>
    <div className="">{label}</div>
  </div>
}

const CheckboxInput = ({checked, onToggle, label, id, className = ''}) => {
  return <div className={`flex flex-row items-center ${className}`}>
    <div className="check">
      <input type='checkbox' className="check__input" id={id} onChange={onToggle} checked={checked} disabled={false}/>
      <div className="check__box" onChange={onToggle}/>
    </div>
    <div className="text-xs text-[#A8A8A8]">{label}</div>
  </div>
}

export {TextInput, SelectInput, RadioInput, CheckboxInput};